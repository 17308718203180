import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../shared/css/globalStyles'

import Heading from './header'
import Footer from './footer'
import styled from 'react-emotion'

const Wrapper = styled('div')`
  display: grid;
`

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: '30+' },
            { name: 'keywords', content: '30+, plebiscito' },
          ]}
        >
          <html lang="es" />
        </Helmet>
        <Wrapper location={location}>
          <Heading
            location={location}
            siteTitle={data.site.siteMetadata.title}
          />
          {children}
          <Footer />
        </Wrapper>
      </React.Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
