import React from 'react'
import Link from 'gatsby-link'
import styled, { css } from 'react-emotion'
import Responsive from 'react-responsive'

import Menu from 'react-burger-menu/lib/menus/slide'
import LogoPurple from '../images/logo-purple.svg'
import LogoCyan from '../images/logo-cyan.svg'

const Default = props => <Responsive {...props} maxWidth={'40em'} />
const Desktop = props => <Responsive {...props} minWidth={'40.001em'} />

const Header = styled('div')`
  display: grid;
  max-width: 960px;
  width: 100%;
  padding: 1em;
  justify-self: center;
  align-items: center;
  grid-template-columns: 1fr 2fr;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  a {
    font-weight: bold;
    color: #42258a;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

var menuStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '36px',
    height: '30px',
    right: '0',

    float: 'right',
  },
  bmBurgerBars: {
    background: '#42258A',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    transform: 'scale(2.5)',
    top: '2em',
    right: '2em',
  },
  bmCross: {
    background: '#a4ffff',
  },
  bmMenu: {
    background: '#42258A',
    padding: '1em',
    fontSize: '2em',
    textAlign: 'center',
  },
  bmMenuWrap: {
    top: 0,
  },
  bmMorphShape: {
    fill: '#42258A',
  },
  bmItemList: {
    color: '#a4ffff',
    padding: '1em',
    margin: '0 auto',
    maxWidth: '960px',
  },
  bmItem: {
    display: 'block',
    color: '#a4ffff',
    fontWeight: 'bold',
    textDecoration: 'none',
    marginBottom: '1em',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}

class Heading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false })
  }

  cronoLink() {
    if (this.props.location === '/') {
      return (
        <a
          className="menu-item"
          onClick={() => this.closeMenu()}
          href="#cronologia"
        >
          Cronología
        </a>
      )
    } else {
      return (
        <Link
          className="menu-item"
          onClick={() => this.closeMenu()}
          to="/#cronologia"
        >
          Cronología
        </Link>
      )
    }
  }

  render() {
    return (
      <Header>
        <Link title="30 + | Con futuro, con todos - Inicio" to="/">
          <LogoPurple
            className={css({
              minHeight: '2.5em',
              maxWidth: '10em',
            })}
          />
        </Link>
        <Default>
          <Menu
            right
            width={'100%'}
            styles={menuStyles}
            isOpen={this.state.menuOpen}
            onStateChange={state => this.handleStateChange(state)}
          >
            <Link
              title="30 + | Con futuro, con todos"
              onClick={() => this.closeMenu()}
              to="/"
            >
              <LogoCyan
                className={css({
                  maxWidth: '300px',
                  maxHeight: '110px',
                  margin: '0 auto',
                  marginBottom: '1.5em',
                })}
              />
            </Link>

            <Link className="menu-item" onClick={() => this.closeMenu()} to="/">
              Inicio
            </Link>

            <Link
              className="menu-item"
              onClick={() => this.closeMenu()}
              to="/testimonios"
            >
              Testimonios
            </Link>

            <Link
              className="menu-item"
              onClick={() => this.closeMenu()}
              to="/acerca"
            >
              Acerca del proyecto
            </Link>
            {this.cronoLink()}
            <Link
              className="menu-item"
              onClick={() => this.closeMenu()}
              to="/colabora"
            >
              Colabora
            </Link>
          </Menu>
        </Default>

        {/* desktop nav */}
        <Desktop>
          <Nav>
            <Link className="menu-item" onClick={() => this.closeMenu()} to="/">
              Inicio
            </Link>
            <Link className="menu-item" to="/testimonios">
              Testimonios
            </Link>
            <Link className="menu-item" to="/acerca">
              Acerca del proyecto
            </Link>

            <Link className="menu-item" to="/colabora">
              Colabora
            </Link>
          </Nav>
        </Desktop>
      </Header>
    )
  }
}

export default Heading
