import BarlowBlackTTF from 'fonts/Barlow-Medium.ttf'
import BarlowMediumWOFF from 'fonts/Barlow-Medium.woff'
import BarlowBoldTTF from 'fonts/Barlow-Bold.ttf'
import BarlowBoldWOFF from 'fonts/Barlow-Bold.woff'
import CubanoRegularTTF from 'fonts/Cubano-Regular.ttf'
import CubanoRegularWOFF from 'fonts/Cubano-Regular.woff'

export default {
  BarlowBlackTTF,
  BarlowMediumWOFF,
  BarlowBoldTTF,
  BarlowBoldWOFF,
  CubanoRegularTTF,
  CubanoRegularWOFF,
}
