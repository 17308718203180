import React from 'react'
import Link from 'gatsby-link'
import { css } from 'react-emotion'

export default props => (
  <Link
    to={props.linkTo}
    className={css({
      width: '300px',
      flex: '0 0 300px',
      position: 'relative',
      height: '350px',
      // marginRight: '2em',
      marginRight: `${props.marginright}`,
      background: `url(${props.bgImg}) center no-repeat`,
      backgroundSize: 'cover',
      textDecoration: 'none',
      opacity: 0.9,
      '&:hover': {
        opacity: 1,
      },
    })}
  >
    <h2
      className={css({
        color: '#A4ffff',
        lineHeight: '150%',
        padding: '1em',
        margin: 0,
      })}
    >
      {props.title}
    </h2>
  </Link>
)
