import React from 'react'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import PostSummary from '../components/postSummary'
import { graphql } from 'gatsby'

import HeroBg from '../images/bg-landing.jpg'

const Hero = styled.div`
  color: #a4ffff;
  display: flex;
  align-items: center;
  background: hotpink url(${HeroBg}) bottom right no-repeat;
  background-size: cover;
  position: relative;
  @media (min-width: 420px) {
    min-height: 200px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(66, 46, 134, 0.5);
    transition: all 0.3s linear;
  }

  & h1 {
    position: relative;
  }
`

class Testimonios extends React.Component {
  render() {
    const testimonios = this.props.data.allWordpressPost.edges
    return (
      <Layout>
        <Hero>
          <div
            className={css({
              maxWidth: '960px',
              margin: '0 auto',
              padding: '1em',
            })}
          >
            <h1>Testimonios</h1>
          </div>
        </Hero>
        <div
          className={css({
            display: 'grid',
            width: '100%',
            margin: '0 auto',
            padding: '2em',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr));',
            gridGap: '2em',
            justifyItems: 'center',
            backgroundColor: '#e3e6f6',
          })}
        >
          {testimonios.map((testimonio, i) => {
            const img =
              testimonio.node.acf.imagen_portadilla.localFile.childImageSharp
                .fixed.src

            return (
              <PostSummary
                key={`${testimonio.node.id}`}
                title={`${testimonio.node.title}`}
                linkTo={`/${testimonio.node.slug}`}
                bgImg={img}
                marginRight="0"
              />
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default Testimonios

export const pageQuery = graphql`
  query {
    allWordpressPost(
      filter: { acf: { es_testimonio: { eq: true } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date
          modified
          id
          title
          slug

          acf {
            es_testimonio
            imagen_portadilla {
              localFile {
                size
                childImageSharp {
                  fixed(width: 300, height: 350) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
