import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { css } from 'react-emotion'

class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.wordpressPost

    return (
      <Layout location={this.props.location}>
        <div
          className={css({
            backgroundColor: '#D4FFFF',
            color: '#42258A',
          })}
        >
          <div
            className={css({
              maxWidth: '960px',
              margin: '0 auto',
              padding: '1em',
            })}
          >
            <h1
              className={css({
                padding: '1em 0',
              })}
              dangerouslySetInnerHTML={{ __html: post.title }}
            />

            {post.acf &&
              post.acf.contenido_post_post &&
              post.acf.contenido_post_post.map((layout, i) => {
                if (layout.__typename === `WordPressAcf_texto`) {
                  const texto = layout.texto
                  return (
                    <div
                      key={`${i} text`}
                      css={{ marginBottom: '2em', lineHeight: '1.5em' }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: texto }} />
                    </div>
                  )
                }
                if (layout.__typename === `WordPressAcf_video`) {
                  const id_video = layout.id_video
                  return (
                    <div
                      key={`${i} video`}
                      className="embed-container"
                      css={{ marginBottom: '2em' }}
                    >
                      <iframe
                        title={`${i}`}
                        src={`https://www.youtube.com/embed/${id_video}?rel=0`}
                        frameBorder="0"
                        allowFullScreen="allowfullscreen"
                      />
                    </div>
                  )
                }
                if (layout.__typename === `WordPressAcf_fotos`) {
                  return (
                    <div key={`${i} galeria`}>
                      {layout.fotos.map(({ localFile, i }) => {
                        const img = localFile.childImageSharp.fluid
                        return (
                          <Img
                            css={{ marginBottom: '2em' }}
                            key={img.src}
                            fluid={img}
                          />
                        )
                      })}
                    </div>
                  )
                }
                return null
              })}
          </div>
        </div>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      acf {
        contenido_post_post {
          __typename
          ... on WordPressAcf_texto {
            texto
          }
          ... on WordPressAcf_video {
            id_video
          }

          ... on WordPressAcf_fotos {
            fotos {
              id
              localFile {
                size
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
