import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout style={{ maxWidth: '960px', margin: '0 auto' }}>
    <h1>No encontrado</h1>
  </Layout>
)

export default NotFoundPage
