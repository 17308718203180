// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/Users/ds/local.dev/freelance/factorcritico/30/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/Users/ds/local.dev/freelance/factorcritico/30/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-js": () => import("/Users/ds/local.dev/freelance/factorcritico/30/src/pages/acerca.js" /* webpackChunkName: "component---src-pages-acerca-js" */),
  "component---src-pages-colabora-js": () => import("/Users/ds/local.dev/freelance/factorcritico/30/src/pages/colabora.js" /* webpackChunkName: "component---src-pages-colabora-js" */),
  "component---src-pages-index-js": () => import("/Users/ds/local.dev/freelance/factorcritico/30/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testimonios-js": () => import("/Users/ds/local.dev/freelance/factorcritico/30/src/pages/testimonios.js" /* webpackChunkName: "component---src-pages-testimonios-js" */)
}

exports.data = () => import("/Users/ds/local.dev/freelance/factorcritico/30/.cache/data.json")

