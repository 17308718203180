import React from 'react'
import Layout from '../components/layout'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styled, { css } from 'react-emotion'

import ImgAcerca from '../images/header_acercade.png'

const Hero = styled.div`
  color: #a4ffff;
  display: flex;
  align-items: center;
  background: hotpink url(${ImgAcerca}) bottom right no-repeat;
  background-size: cover;
  position: relative;
  min-height: 400px;

  @media (min-width: 420px) {
    min-height: 600px;
  }
`

const wrapper = css({
  maxWidth: '960px',
  padding: '1em',
  margin: '0 auto',
})

const colorCyan = css({
  color: '#a4ffff',
})

const Acerca = props => (
  <Layout location={props.location}>
    <Hero />
    <div
      className={css({
        backgroundColor: '#42258a',
        color: '#D4FFFF',
        lineHeight: '150%',
      })}
    >
      <div className={wrapper}>
        <h2>Acerca del proyecto</h2>
        <p>
          #30MásChile es una invitación a recordar y proyectar, a celebrar
          nuestro pasado y soñar con nuestro futuro.
        </p>
        <p>
          El 5 de Octubre de 1988, los chilenos y chilenas salieron de sus casas
          para derrotar a la dictadura militar en las urnas.{' '}
          <em>
            ¿Dónde estabas ese 5 de octubre? ¿Acompañaste a tus padres o abuelos
            a votar? ¿Qué hiciste durante los días de campaña previos al
            plebiscito?
          </em>
        </p>
        <p>
          <strong>Queremos conocer tu historia</strong>.
        </p>
        <p>
          Queremos convertir la memoria en futuro y recuperar la confianza que
          tuvimos todos en un proyecto colectivo.
        </p>

        <p>
          Queremos recrear las miles de historias anónimas, de personas que como
          tú o tus familiares y amistades, que vencieron el miedo en 1988. Son
          memorias personales, familiares, que nos ayudarán a construir un
          futuro mejor, más libre y más justo para Chile.
        </p>
        <p>
          Envíanos tus fotos, textos o mándanos un video en formato selfie
          contándonos tu experiencia o la de tu familia, para que sea parte de
          la gran memoria ciudadana del plebiscito de 1988.
        </p>
        <p>
          <strong>#30MásChile</strong> es un proyecto de la{' '}
          <OutboundLink className={colorCyan} href="http://www.fdd.cl/">
            Fundación Democracia y Desarrollo
          </OutboundLink>
          .
        </p>

        <h3>Colaboradores</h3>

        <ul
          className={css({
            paddingLeft: '1em',
          })}
        >
          <li>
            <OutboundLink
              className={colorCyan}
              href="http://www.bibliotecanacional.cl/sitio/"
            >
              Biblioteca Nacional
            </OutboundLink>
          </li>
          <li>
            <OutboundLink className={colorCyan} href="http://www.fpd.cl/">
              Fundación por la Democracia
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              className={colorCyan}
              href="https://www.elquintopoder.cl/"
            >
              El Quinto Poder
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              className={colorCyan}
              href="https://ww3.museodelamemoria.cl/"
            >
              Museo de la Memoria
            </OutboundLink>
          </li>
          <li>
            <OutboundLink className={colorCyan} href="https://www.tvn.cl/">
              Televisión Nacional de Chile
            </OutboundLink>
          </li>
          <li>
            <OutboundLink className={colorCyan} href="https://tironi.cl/">
              Tironi
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              className={colorCyan}
              href="http://www.archivonacional.cl/sitio/"
            >
              Archivo Nacional
            </OutboundLink>
          </li>
        </ul>

        <p>
          Desarrollado por{' '}
          <OutboundLink
            className={colorCyan}
            href="https://www.factorcritico.cl/"
          >
            Factor Crítico
          </OutboundLink>
        </p>
      </div>
    </div>
  </Layout>
)

export default Acerca
