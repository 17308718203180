import React from 'react'
import Layout from '../components/layout'
import { css } from 'emotion'
import ImgColabora from '../images/colabora.jpeg'

const Colabora = props => (
  <Layout location={props.location}>
    <div
      className={css({
        backgroundColor: '#E3E6FF',
        color: '#42258A',
        lineHeight: '150%',
      })}
    >
      <div
        className={css({
          maxWidth: '960px',
          padding: '1em',
          margin: '0 auto',
          textAlign: 'center',
        })}
      >
        <h2>Colabora</h2>
        <img src={ImgColabora} className={css({width:'100%', height: 'auto', maxWidth: '700px'})} alt="" />
      </div>
    </div>
  </Layout>
)

export default Colabora
