import { injectGlobal } from 'react-emotion'
import fontFiles from './fonts'
import './reset-styles'

export default injectGlobal`

  @font-face {
    font-family: "Barlow-Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Barlow Bold"), local("Barlow-Bold"), url(${
      fontFiles.BarlowBoldTTF
    }) format("ttf"), url(${fontFiles.BarlowBoldWOFF}) format("woff");
  }

  @font-face {
    font-family: "Barlow-Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Barlow Medium"), local("Barlow-Medium"), url(${
      fontFiles.BarlowMediumTTF
    }) format("ttf"), url(${fontFiles.BarlowMediumWOFF}) format("woff");
  }

  @font-face {
    font-family: "Cubano-Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Cubano Regular"), local("Cubano-Regular"), url(${
      fontFiles.CubanoRegularTTF
    }) format("ttf"), url(${fontFiles.CubanoRegularWOFF}) format("woff");
  }
 * {
   box-sizing: border-box;
 }
  html {
    font-family: 'Barlow-Medium', serif;
  }
  h1, h2 {
    font-family: 'Cubano-Regular', serif;
    font-weight: 300;
  }

  img.size-full {
    width: 100%;
    height: auto;
  }

  .bm-overlay,
.bm-menu-wrap {
	transition-duration: .3s !important;
}
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
  `
