import React, { Fragment } from 'react'
import styled, { css } from 'react-emotion'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import IcoFb from '../images/ico-fb.svg'
import IcoTw from '../images/ico-tw.svg'
import IcoIg from '../images/ico-ig.svg'
import IcoWa from '../images/ico-wa.svg'
import ImgFdd from '../images/logofdd_calipso.svg'

const Social = styled.div`
  display: grid;
  color: ${props => props.color};
  background-color: ${props => props.bgcolor};
  align-items: center;
  text-decoration: none;
`

const SocialLink = Social.withComponent(OutboundLink)

const GridContainer = styled.div`
  display: grid;
  padding: 1em;
  max-width: 960px;
  justify-self: center;
  align-items: center;
`

const Footer = styled.div`
  background-color: #42258a;
  color: #a4ffff;
`

export default () => (
  <Fragment>
    <SocialLink
      color="#D4FFFF"
      bgcolor="#42258A"
      aria-label="Facebook"
      href="https://www.facebook.com/30MasChile/"
    >
      <GridContainer
        className={css({
          gridTemplateColumns: '4fr 1fr',
        })}
      >
        <p>Colabora con tu testimonio en Facebook</p>
        <IcoFb className={css('max-height:35px;')} />
      </GridContainer>
    </SocialLink>
    <SocialLink
      color="#D4FFFF"
      bgcolor="#283590"
      aria-label="WhatsApp"
      href="https://api.whatsapp.com/send?phone=56979816946"
    >
      <GridContainer
        className={css({
          gridTemplateColumns: '4fr 1fr',
        })}
      >
        <p>
          También al <strong>+56 9 7981 6946</strong> en WhatsApp
        </p>
        <IcoWa className={css('max-height:35px;')} />
      </GridContainer>
    </SocialLink>
    <Social color="#42258A" bgcolor="#A4FFFF">
      <GridContainer
        className={css({
          gridTemplateColumns: '3fr 1fr 1fr',
        })}
      >
        <p>
          Sigue el proyecto en <strong>#30MasChile</strong> y en
        </p>
        <a aria-label="Twitter" href="https://twitter.com/30maschile">
          <IcoTw className={css('max-height:35px;')} />
        </a>

        <a aria-label="Instagram" href="https://www.instagram.com/30maschile/">
          <IcoIg className={css('max-height:35px;')} />
        </a>
      </GridContainer>
    </Social>
    <Footer>
      <div
        className={css({
          maxWidth: '960px',
          margin: '0 auto',
          // padding: '1em',
          display: 'flex',
        })}
      >
        <a
          href="http://www.fdd.cl/"
          className={css({
            textDecoration: 'none',
            color: '#a4ffff',
            display: 'flex',
            gridTemplateRows: '1fr 3fr',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '100%',
            alignItems: 'center',
          })}
        >
          <p>Un proyecto de</p>
          <ImgFdd
            className={css({
              margin: '1em',
              minWidth: '100px',
              maxHeight: '85px',
            })}
          />
        </a>
      </div>
    </Footer>
  </Fragment>
)
