import React from 'react'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import ScrollableAnchor from 'react-scrollable-anchor'
import PostList from '../components/postList'

import HeroBg from '../images/bg-landing.jpg'

const Hero = styled.div`
  color: #a4ffff;
  display: flex;
  align-items: center;
  background: hotpink url(${HeroBg}) bottom right no-repeat;
  background-size: cover;
  position: relative;
  @media (min-width: 40em) {
    min-height: 400px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(66, 46, 134, 0.5);
    transition: all 0.3s linear;
  }
`
const IndexPage = props => (
  <Layout location={props.location.pathname}>
    <Hero>
      <div
        className={css({
          maxWidth: '960px',
          margin: '0 auto',
          padding: '1em',
        })}
      >
        <Link
          to="/colabora"
          className={css({
            textDecoration: 'none',
            color: '#a4ffff',
          })}
        >
          <h1
            className={css({
              // fontSize: '2em',
              lineHeight: '120%',
              position: 'relative',
              fontSize: '1.75em',
              textAlign: 'left',
              '@media (min-width: 420px)': {
                fontSize: '3em',
              },
            })}
          >
            ¿Dónde estabas el 5 de octubre de 1988? ¿Qué hiciste durante los 30
            días previos al plebiscito?
            <br />
            <br />
            ¡Queremos conocer tu historia!
          </h1>
        </Link>
      </div>
    </Hero>

    <ScrollableAnchor id={'cronologia'}>
      <div id="cronologia" />
    </ScrollableAnchor>

    <PostList data={props.data} />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressWpFecha {
      edges {
        node {
          id
          title
          acf {
            contenidos {
              wordpress_id
              post_title
              post_name
              acf {
                imagen_portadilla {
                  localFile {
                    size
                    childImageSharp {
                      fixed(width: 300, height: 350) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
