import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

import PostSummary from '../components/postSummary'

const PostListContainer = styled.div`
  background-color: #e3e6f6;
  &:nth-child(odd) {
    background-color: #a4ffff;
  }
  display: block;
`

const PostListHeader = styled.div`
  background-color: #42258a;
  width: 100%;
  h3 {
    font-size: 1.5em;
    max-width: 960px;
    margin: 0 auto;
    color: #a4ffff;
    padding: 1em;
  }
`

const PostListContentWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`

const PostListContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100vw;
  padding: 1em;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`

class PostList extends React.Component {
  render() {
    const fechas = this.props.data.allWordpressWpFecha.edges
    return (
      <React.Fragment>
        {fechas.map((fecha, i) => {
          const postList = fecha.node.acf.contenidos

          return (
            <PostListContainer key={`${fecha.node.id}`}>
              <PostListHeader>
                <h3>{fecha.node.title}</h3>
              </PostListHeader>

              <PostListContentWrapper>
                <PostListContent>
                  {postList.map((post, i) => {
                    const img =
                      post.acf.imagen_portadilla.localFile.childImageSharp.fixed
                        .src

                    return (
                      <PostSummary
                        key={`${post.wordpress_id}`}
                        title={`${post.post_title}`}
                        linkTo={`/${post.post_name}`}
                        bgImg={img}
                        marginright="2em"
                      />
                    )
                  })}
                </PostListContent>
              </PostListContentWrapper>
            </PostListContainer>
          )
        })}
      </React.Fragment>
    )
  }
}

PostList.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostList
