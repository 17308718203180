module.exports = [{
      plugin: require('/Users/ds/local.dev/freelance/factorcritico/30/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ds/local.dev/freelance/factorcritico/30/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-125396954-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/ds/local.dev/freelance/factorcritico/30/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
